import React, { useState } from 'react';

const OrderInfoPage = () => {
  const [textInput, setTextInput] = useState(''); // Состояние для текстового input

  const handleTextChange = (event) => {
    setTextInput(event.target.value); // Обновляем состояние при изменении текста в input
  };

  const handleFindClick = () => {
    // Логика для обработки нажатия на кнопку "Найти" может быть добавлена здесь
    console.log('Нажата кнопка "Найти", введенный текст:', textInput);
  };

  return (
    <div>
      <input 
        type="text" 
        value={textInput} 
        onChange={handleTextChange} 
        placeholder="Введите текст" 
      />
      <button onClick={handleFindClick}>Найти</button>
    </div>
  );
}

export default OrderInfoPage;
