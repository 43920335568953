import React, { useState } from 'react';
import './AdminPanel.css'; 
import Users from './Users/Users'
import AllStat from './Allstat/Allstat'
import Orders from './Orders/Orders';
import OrderInfo from './Orders/OrderInfo/OrderInfo'

const AdminPanel = () => {
    const [selectedTab, setSelectedTab] = useState('subscriptions');
    const [sidebarVisible, setSidebarVisible] = useState(true);


    const toggleSidebar = () => {
      setSidebarVisible(!sidebarVisible);
    };
    
    // Объект для управления содержимым вкладок
    const tabsContent = {
      subscriptions: (
        <div>
          <h2>Общая статистика</h2>
          <AllStat />
        </div>
      ),
      orders: (
        <div>
          <h2>Заказы</h2>
          <Orders />
        </div>
      ),
      edit_orders: (
        <div>
          <h2>Редактирование заказа</h2>
          <OrderInfo />
        </div>
      ),
      users: (
        <div>
          <h2>Пользователи</h2>
          <Users />
        </div>
      )
    };

  return (
    <div className="admin-panel">
      <div className={`sidebar ${!sidebarVisible ? 'hidden' : ''}`}>
        {/* <button onClick={toggleSidebar} className="sidebar-button">Скрыть/Показать панель</button> */}
        <button onClick={() => setSelectedTab('subscriptions')} className="sidebar-button">Общая статистика</button>
        <button onClick={() => setSelectedTab('users')} className="sidebar-button">Пользователи бота</button>
        <button onClick={() => setSelectedTab('orders')} className="sidebar-button">Заказы</button>
        <button onClick={() => setSelectedTab('edit_orders')} className="sidebar-button">Редактирование заказа</button>
        <button onClick={() => setSelectedTab('orders')} className="sidebar-button">Рассылка</button>
        <button onClick={() => setSelectedTab('orders')} className="sidebar-button">Создать накладную</button>
      </div>



      <div className={`content ${sidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
        <button onClick={toggleSidebar} className="sidebar-button">Скрыть/Показать панель</button>
        <h1>Админ-панель</h1>
        {tabsContent[selectedTab] || <div>Выберите вкладку</div>}
      </div>
    </div>
  );
};

export default AdminPanel;
