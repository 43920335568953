import React, { useState } from 'react';
import '../App.css';
import Logo from '../content/logo.jpg';
import AdminPanel from './AdminPanel';


const VerificationPage = () => {
  const [code, setCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (code === process.env.REACT_APP_PASSWORD) {
      setIsVerified(true);
    } else {
      alert('Неверный мастер пароль');
    }
  };

  return (
    <>
      {isVerified ? (
        <AdminPanel /> // Админ панель будет отображаться после верификации
      ) : (
        <div className="verification-page">
          <img src={Logo} alt="Logo" className="rounded-image" />
          <div className="phone-number">
            <p>SWICHSALLER Админ панель</p>
          </div>
          <div className="info-text">
            <p>Для доступа введите свой мастер пароль</p>
          </div>
          <form onSubmit={handleSubmit} className="code-form">
            <input
              id="code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Мастер пароль"
              className="code-input"
            />
            <button type="submit" className="submit-button">Войти</button>
          </form>
        </div>
      )}
    </>
  );
};

export default VerificationPage;
