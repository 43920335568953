import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../AdminPanel.css';
import './Orders.css';



const Orders = () => {
  const [Orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);


  const [filters, setFilters] = useState({
    id: "",
    user_id: "",
    fio: "",
    phone: "",
    status: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    const fetchOrders = async () => {
        try {
          const response = await axios.get('https://backend-swich.plutus-fin.ru/api/get_orders', {
            headers: {
              'token': process.env.REACT_APP_TOKEN_admin
            }
          });
          return response.data.orders.map(order => ({
            id: order[0],
            user_id: order[1],
            fio: order[3],
            phone: order[4],
            status: order[6]
          }));
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
          return [];
        }
      };
      

    const loadData = async () => {
      setIsLoading(true);
      const fetchedOrders = await fetchOrders();
      setOrders(fetchedOrders);
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const startIndex = (page - 1) * 15;
  const endIndex = page * 15;
  const filteredOrders = Orders.filter(order =>
    (order.id && order.id.toString().includes(filters.id)) &&
    (order.user_id && order.user_id.toString().includes(filters.user_id)) &&
    (order.fio && order.fio.toLowerCase().includes(filters.fio.toLowerCase())) &&
    (order.phone && order.phone.includes(filters.phone)) &&
    (order.status && order.status.toLowerCase().includes(filters.status.toLowerCase()))
  );
  
  

  return (
    <div className="user-table">
        <div className="header-orders">
            <h2>Список Заказов, Всего:{Orders.length} - Ордеров </h2>

        </div>





      {isLoading ? (
        <p>Загрузка...</p>
      ) : (
        <React.Fragment>
          <table>
            <thead>
              <tr>


                <th>
                    ID ордера <br />
                    <input
                    type="text"
                    name="id"
                    className='code-input selector-checked'
                    value={filters.id}
                    onChange={handleFilterChange}
                    placeholder="Поиск по ID"
                    />
                </th>
                
                
                <th>
                    ID Пользователя
                    <br />
                        <input
                        type="text"
                        className='code-input selector-checked'
                        name="user_id"
                        value={filters.user_id}
                        onChange={handleFilterChange}
                        placeholder="Поиск по ID пользователя"
                        />
                </th>



                <th>
                    ФИО 
                    <br />
                        <input
                        type="text"
                        className='code-input selector-checked'
                        name="fio"
                        value={filters.fio}
                        onChange={handleFilterChange}
                        placeholder="Поиск по ФИО"
                        />
                </th>


                <th>
                    Телефон
                    <br />
                        <input
                        type="text"
                        className='code-input selector-checked'
                        name="phone"
                        value={filters.phone}
                        onChange={handleFilterChange}
                        placeholder="Поиск по Телефону"
                        />
                </th>
                <th>
                    Статус
                    <br />
                        <select
                        name="status"
                        className='code-input selector-checked'
                        value={filters.status}
                        onChange={handleFilterChange}
                        >
                          <option value="">Выберите статус</option>
                          <option value="Проверка оплаты">Проверка оплаты</option>
                          <option value="Оплачено">Оплачено</option>
                          <option value="Товар отправлен на склад в Китае">Товар отправлен на склад в Китае</option>
                          <option value="Товар прибыл на склад в Китае">Товар прибыл на склад в Китае</option>
                          <option value="Упаковка">Упаковка</option>
                          <option value="Товар отправлен в Россию">Товар отправлен в Россию</option>
                          <option value="Прибыл в Москву, подготовка к отправке в СДЭК">Прибыл в Москву, подготовка к отправке в СДЭК</option>
                          <option value="Проверка оплаты доставки">Проверка оплаты доставки</option>
                          <option value="Прибыл в Москву, ожидает самовывоза">Прибыл в Москву, ожидает самовывоза</option>
                          <option value="Отменен">Отменен</option>
                          <option value="На доработке">На доработке</option>
                        </select>
                </th>

              </tr>
            </thead>
            <tbody>
            {filteredOrders.slice(startIndex, endIndex).map(order => (
                <tr key={order.id}>
                    <td>
                    {order.id}
                    </td>
                    <td>{order.user_id}</td>
                    <td>{order.fio}</td>
                    <td>{order.phone}</td>
                    <td>{order.status}</td>
                </tr>
            ))}

            </tbody>
          </table>
          <div className="pagination">
            <button className='sidebar-button prew' onClick={handlePrevPage} disabled={page === 1}>Предыдущая страница</button>
            <button className='sidebar-button next' onClick={handleNextPage} disabled={endIndex >= Orders.length}>Следующая страница</button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Orders;
