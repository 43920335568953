import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../AdminPanel.css'; 
import './Allstat.css' 
const AllStat = () => {
    const [users, setUsers] = useState([]);
    const [orders, setOrder] = useState([]);
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://backend-swich.plutus-fin.ru/api/get_users', {
                    headers: {
                        'token': process.env.REACT_APP_TOKEN_admin
                    }
                });
                // Передача пользователей в состояние
                setUsers(response.data.users);
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            } finally {
                // Установка isLoading в false после загрузки
                // setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('https://backend-swich.plutus-fin.ru/api/get_orders', {
                    headers: {
                        'token': process.env.REACT_APP_TOKEN_admin
                    }
                });

                setOrder(response.data.orders);
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            } finally {

            }
        };

        fetchOrders();
    }, []);




    return (
        <div className="allstat">
            <div className="stat">
                <h2>{users.length}</h2>
                <p>Количество пользователей</p>
            </div>
            <div className="stat">
                <h2>{orders.length}</h2>
                <p>Количество Заказов</p>
            </div>
            <div className="stat">
                <h2>15</h2>
                <p>Приведено рефералов</p>
            </div>
            <div className="stat">
                <h2>150 000</h2>
                <p>Сумма заказов</p>
            </div>
            <div className="stat">
                <h2>15</h2>
                <p>Заказов от реферальной системы</p>
            </div>
            <div className="stat">
                <h2>15</h2>
                <p>Доставок ожидается</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
            <div className="stat">
                <h2>0</h2>
                <p>Какая то статистика</p>
            </div>
        </div>
    );
};

export default AllStat;
