import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../AdminPanel.css';
import './Users.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://backend-swich.plutus-fin.ru/api/get_users', {
          headers: {
            'token': process.env.REACT_APP_TOKEN_admin
          }
        });
        return response.data.users.map(user => ({
          id: user[0],
          chatId: user[1],
          referralId: user[2],
          verifications: user[3],
          points: user[4]
        }));
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
        return [];
      }
    };

    const loadData = async () => {
      setIsLoading(true);
      const fetchedUsers = await fetchUsers();
      setUsers(fetchedUsers);
      setIsLoading(false);
    };

    loadData();
  }, []);

  const handleNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const startIndex = (page - 1) * 30;
  const endIndex = page * 30;
  const displayedUsers = users.slice(startIndex, endIndex);

  return (
    <div className="user-table">
      <h2>Список пользователей: <br />{users.length} пользователей </h2>
      {isLoading ? (
        <p>Загрузка...</p>
      ) : (
        <React.Fragment>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Чат ID</th>
                <th>Приглашенный реферал ID</th>
                <th>Реферал сделал покупку?</th>
                <th>Бонусы</th>
              </tr>
            </thead>
            <tbody>
              {displayedUsers.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>
                    <a class='url_href' href={`tg://user?id=${user.chatId}`}>{user.chatId}</a>
                    </td>
                  <td>{user.referralId}</td>
                  <td>{user.verifications}</td>
                  <td>{user.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button class='sidebar-button prew' onClick={handlePrevPage} disabled={page === 1}>Предыдущая страница</button>
            <button class='sidebar-button next' onClick={handleNextPage} disabled={endIndex >= users.length}>Следующая страница</button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Users;
